import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap'; // Import the required components from react-bootstrap
import styles from './AdminPage.module.css'; // Import the CSS module

function Chatbots() {
  const [chatbots, setChatbots] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [pageInput, setPageInput] = useState(''); // Track the page input
  const chatbotsPerPage = 10; // Number of chatbots per page
  const [loading, setLoading] = useState(true); // Track loading state
  const [showChatbotModal, setShowChatbotModal] = useState(false); // Track chatbot modal visibility
  const [error, setError] = useState(null); // Track error state
  const [selectedChatbot, setSelectedChatbot] = useState(null); // Track selected chatbot

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  useEffect(() => {
    getChatbots();
  }, []);

  const getChatbots = async () => {
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/getChatbots/');
      setChatbots(response.data.chatbots); // Access the chatbots array
      setLoading(false); // Update loading state
    } catch (error) {
      console.error('Error fetching user chatbots:', error);
      setError('Error fetching user chatbots'); // Update error state
      setLoading(false); // Update loading state
    }
  };

  const deleteChatbot = async (chatbot_id, chatbot_name) => {
    const confirmed = window.confirm(`Are you sure you want to delete chatbot ${chatbot_name}?`);
    if (confirmed) {
      try {
        await axiosInstance.post('https://dryangai.com/api/delete_chatbot/', {
          chatbot_id,
          chatbot_name,
        });
        getChatbots();
      } catch (error) {
        console.error('Error deleting chatbot:', error);
        setError('Error deleting chatbot'); // Update error state
      }
    }
  };

  const indexOfLastChatbot = currentPage * chatbotsPerPage;
  const indexOfFirstChatbot = indexOfLastChatbot - chatbotsPerPage;
  const currentChatbots = chatbots.slice(indexOfFirstChatbot, indexOfLastChatbot);

  const nextPageChatbots = () => {
    if (currentPage < Math.ceil(chatbots.length / chatbotsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageChatbots = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const lastPageChatbots = () => {
    setCurrentPage(Math.ceil(chatbots.length / chatbotsPerPage));
  };

  const firstPageChatbots = () => {
    setCurrentPage(1);
  };

  const handlePageInputChange = (event) => {
    setPageInput(event.target.value);
  };

  const goToPageChatbots = () => {
    const page = Number(pageInput);
    if (page > 0 && page <= Math.ceil(chatbots.length / chatbotsPerPage)) {
      setCurrentPage(page);
    }
    setPageInput('');
  };

  const maskApiKey = (apiKey) => {
    const length = apiKey.length;
    if (length <= 10) {
      return apiKey;
    }
    const start = apiKey.slice(0, 5);
    const end = apiKey.slice(-5);
    return `${start}*****${end}`;
  };

  const handleChatbotClick = (chatbot) => {
    setSelectedChatbot(chatbot);
    setShowChatbotModal(true);
  };

  const handleCloseChatbotModal = () => {
    setShowChatbotModal(false);
    setSelectedChatbot(null);
  };

  return (
    <div>
      {loading ? (
        <p>Loading chatbots...</p>
      ) : chatbots.length > 0 ? (
        <div className={styles['chatbot-list-content']}>
          <h2>Chatbot List</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Model</th>
                <th>API Key</th>
                <th>Status</th>
                <th>Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentChatbots.map(chatbot => (
                <tr key={chatbot.chatbot_id}>
                  <td><a href={`https://dryangai.com/api/media/chatbots/examples/chatbot_Bubble.html?chatbotID=${chatbot.chatbot_id}`} target="_blank" rel="noopener noreferrer">{chatbot.chatbot_id}</a></td>
                  <td><a href={`https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=${chatbot.chatbot_id}`} target="_blank" rel="noopener noreferrer">{chatbot.chatbot_name}</a></td>
                  <td>{chatbot.chatbot_description}</td>
                  <td>{chatbot.chatbot_model}</td>
                  <td>{maskApiKey(chatbot.chatbot_api_key)}</td>
                  <td>{chatbot.chatbot_status}</td>
                  <td>
                    <button className="btn btn-primary btn-sm" onClick={() => handleChatbotClick(chatbot)}>Details</button>
                  </td>
                  <td>
                    <button className="btn btn-danger btn-sm" onClick={() => deleteChatbot(chatbot.chatbot_id, chatbot.chatbot_name)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <button onClick={firstPageChatbots} disabled={currentPage === 1}>First</button>
            <button onClick={prevPageChatbots} disabled={currentPage === 1}>Previous</button>
            <input
              type="text"
              value={pageInput}
              className={styles.pageInput}
              onChange={handlePageInputChange}
              placeholder={currentPage.toString()}
            />
            <button onClick={goToPageChatbots}>Go</button>
            <button onClick={nextPageChatbots} disabled={currentPage === Math.ceil(chatbots.length / chatbotsPerPage)}>Next</button>
            <button onClick={lastPageChatbots} disabled={currentPage === Math.ceil(chatbots.length / chatbotsPerPage)}>Last</button>
          </div>
        </div>
      ) : (
        <p>No chatbots found.</p>
      )}
      <Modal show={showChatbotModal} onHide={handleCloseChatbotModal}>
        <Modal.Header closeButton>
          <Modal.Title>Chatbot Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedChatbot && (
            <div>
              <p><strong>ID:</strong> {selectedChatbot.chatbot_id}</p>
              <p><strong>Name:</strong> {selectedChatbot.chatbot_name}</p>
              <p><strong>Description:</strong> {selectedChatbot.chatbot_description}</p>
              <p><strong>Model:</strong> {selectedChatbot.chatbot_model}</p>
              <p><strong>Token Used:</strong> {selectedChatbot.chatbot_token_used}</p>
              <p><strong>API Key:</strong> {maskApiKey(selectedChatbot.chatbot_api_key)}</p>
              <p><strong>Status:</strong> {selectedChatbot.chatbot_status}</p>
              <p><strong>Created At:</strong> {selectedChatbot.chatbot_created_at}</p>
              <p><strong>Updated At:</strong> {selectedChatbot.chatbot_updated_at}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseChatbotModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Chatbots;
