import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Sidebar from './SidebarNew';

function ChatbotConfigPage() {
  const { t } = useTranslation();
  const [chatbots, setChatbots] = useState([]);
  const [selectedChatbotName, setSelectedChatbotName] = useState('');
  const [selectedChatbotID, setSelectedChatbotID] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [domain, setDomain] = useState('');
  const [allowedDomains, setAllowedDomains] = useState([]);
  const [exampleQuestions, setExampleQuestions] = useState([]);
  const [question, setQuestion] = useState('');
  const [greetingMessage, setGreetingMessage] = useState('Hello');
  const [userInputPlaceHolder, setUserInputPlaceHolder] = useState('Chat with chatbot');
  const [chatbotAlias, setChatbotAlias] = useState('');
  const profileImageInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [imageError, setImageError] = useState('');
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());
  const [modalMessage, setModalMessage] = useState('');
  const [chatbotRAGStatus, setChatbotRAGStatus] = useState(false);
  const [chatbot_login_required, setChatbotLoginRequired] = useState(false);
  const [chatbotPersonality, setChatbotPersonality] = useState('');
  const [chatbotLanguage, setChatbotLanguage] = useState('');
  const [chatbotModel, setChatbotModel] = useState('');
  const [modelOptions, setModelOptions] = useState([]); // State for model options

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // Fetch chatbots data for the user on component mount
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = user ? user.user_id : null;

    if (user_id) {
      getUserChatbots(user_id);
    } else {
      console.error('User not authenticated');
    }

    // Fetch model options
    fetchModelOptions();
  }, []);

  const getUserChatbots = async (user_id) => {
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/getUserChatbots/', {
        user_id,
      });
      setChatbots(response.data.chatbots);
    } catch (error) {
      console.error('Error fetching user chatbots:', error);
    }
  };

  // Fetch model options from the backend
  const fetchModelOptions = async () => {
    try {
      const response = await axios.get('https://dryangai.com/api/getOllamaModels');
      setModelOptions(response.data.model_names); // Set the list of models
    } catch (error) {
      console.error('Error fetching model options:', error);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!validExtensions.includes(fileExtension)) {
      setImageError('Only image formats (JPG, JPEG, PNG, GIF) are allowed.');
      return;
    }
    const formData = new FormData();
    formData.append('chatbot_image', file);
    formData.append('chatbot_id', selectedChatbotID);

    try {
      const response = await axios.post('https://dryangai.com/api/uploadChatbotImage/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setImageTimestamp(response.data.unique_id);
      setImageError('');
    } catch (error) {
      console.error('Error uploading chatbot image:', error);
      setModalMessage('Error uploading chatbot image');
      setShowModal(true);
    }
  };

  const handleImageClick = () => {
    profileImageInputRef.current.click();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const updateChatbotInfo = async () => {
    if (!selectedChatbotID) return;

    try {
      const { status } = await axiosInstance.post('https://dryangai.com/api/updateChatbotInfo/', {
        chatbot_id: selectedChatbotID,
        chatbot_alias: chatbotAlias,
        chatbot_personality: chatbotPersonality,
        chatbot_userInputPlaceholder: userInputPlaceHolder,
        chatbot_greeting: greetingMessage,
        chatbot_RAG_status: chatbotRAGStatus,
        chatbot_login_required: chatbot_login_required,
        chatbot_language: chatbotLanguage,
        chatbot_model: chatbotModel,
      });
      if (status === 200) {
        alert('Chatbot updated successfully');
      } else {
        alert('Failed to update chatbot');
      }
    } catch (error) {
      console.error('Error updating chatbot:', error);
      alert('An error occurred while updating chatbot. Please try again.');
    }
  };

  const getChatbotInfo = async () => {
    if (!selectedChatbotID) return;
    try {
      const { data: responseData, status } = await axiosInstance.post('https://dryangai.com/api/getChatbotInfo/', {
        chatbotID: selectedChatbotID,
      });
      if (status === 200) {
        setUserInputPlaceHolder(responseData.chatbot['chatbot_userInputPlaceholder']);
        setGreetingMessage(responseData.chatbot['chatbot_greeting']);
        setChatbotAlias(responseData.chatbot['chatbot_alias']);
        setChatbotRAGStatus(responseData.chatbot['chatbot_RAG_status']);
        setChatbotPersonality(responseData.chatbot['chatbot_personality']);
        setChatbotLoginRequired(responseData.chatbot['chatbot_login_required']);
        setChatbotLanguage(responseData.chatbot['chatbot_language']);
        setChatbotModel(responseData.chatbot['chatbot_model']);
      } else {
        console.error('Failed to fetch chatbot info');
      }
    } catch (error) {
      console.error('Error fetching chatbot info:', error);
    }
  };

  const handleChatbotChange = (event) => {
    const selectedChatbotName = event.target.value;
    setSelectedChatbotName(selectedChatbotName);
    const selectedChatbot = chatbots.find(chatbot => chatbot.chatbot_name === selectedChatbotName);
    setSelectedChatbotID(selectedChatbot ? selectedChatbot.chatbot_id : '');
  };

  const fetchAllowedDomains = async () => {
    if (!selectedChatbotID) return;
    try {
      const { data: responseData, status } = await axiosInstance.post('https://dryangai.com/api/checkDomains/', {
        chatbot_id: selectedChatbotID,
      });
      if (status === 200) {
        setAllowedDomains(responseData.domains);
      } else {
        console.error('Failed to fetch allowed domains');
      }
    } catch (error) {
      console.error('Error fetching allowed domains:', error);
    }
  };

  const fetchExampleQuestions = async () => {
    if (!selectedChatbotID) return;

    try {
      const { data: responseData, status } = await axiosInstance.post('https://dryangai.com/api/getExampleQuestions/',{
        chatbot_id: selectedChatbotID,
    });
      if (status === 200) {
        setExampleQuestions(responseData.exampleQuestions);
      } else {
        console.error('Failed to fetch example questions');
      }
    } catch (error) {
      console.error('Error fetching example questions:', error);
    }
  };

  useEffect(() => {
    if (selectedChatbotID) {
      getChatbotInfo();
      fetchAllowedDomains();
      fetchExampleQuestions();
    }
  }, [selectedChatbotID]);

  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };

  const handleExampleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const deleteDomain = async (domain_id) => {
    try {
      await axiosInstance.post('https://dryangai.com/api/deleteDomain/', {
        chatbot_id: selectedChatbotID,
        domain_id: domain_id,
      });
      fetchAllowedDomains();
    } catch (error) {
      console.error('Error deleting domain:', error);
      alert('An error occurred while deleting the domain. Please try again.');
    }
  }

  const deleteExampleQuestion = async (question_id) => {
    try {
      await axiosInstance.post('https://dryangai.com/api/deleteExampleQuestion/', {
        chatbot_id: selectedChatbotID,
        question_id: question_id,
      });
      fetchExampleQuestions();
    } catch (error) {
      console.error('Error deleting question:', error);
      alert('An error occurred while deleting the question. Please try again.');
    }
  }

  const addDomain = async () => {
    if (!selectedChatbotName) {
      alert('Please choose a chatbot before submitting the domain');
      return;
    }
    if (!domain) {
      alert('Please enter a domain before submitting');
      return;
    }
    try {
      await axiosInstance.post('https://dryangai.com/api/addDomain/', {
        chatbot_name: selectedChatbotName,
        chatbot_id: selectedChatbotID,
        domain: domain,
      });
      fetchAllowedDomains();
      setDomain(''); // Clear the domain input field
    } catch (error) {
      console.error('Error submitting domain:', error);
      alert('An error occurred while submitting the domain. Please try again.');
    }
  };

  const addExampleQuestion = async () => {
    if (!selectedChatbotName) {
      alert('Please choose a chatbot before submitting the example question');
      return;
    }
    if (!question) {
      alert('Please enter a question before submitting');
      return;
    }
    try {
      await axiosInstance.post('https://dryangai.com/api/addExampleQuestion/', {
        chatbot_name: selectedChatbotName,
        chatbot_id: selectedChatbotID,
        question: question,
      });
      fetchExampleQuestions();
      setQuestion(''); // Clear the question input field
    } catch (error) {
      console.error('Error submitting question:', error);
      alert('An error occurred while submitting the question. Please try again.');
    }
  };

  const handleChatbotRAGStatusToggle = async (selectedChatbotID, chatbotRAGStatus) => {
    const newStatus = !chatbotRAGStatus;

    try {
      await axiosInstance.post('https://dryangai.com/api/updateChatbotInfo/', {
        chatbot_id: selectedChatbotID,
        chatbot_RAG_status: newStatus,
      });
      getChatbotInfo();
    } catch (error) {
      console.error('Error updating chatbot RAG status:', error);
    }
  };

  const handleChatbotLoginRequiredToggle = async (selectedChatbotID, chatbot_login_required) => {
    const newStatus = !chatbot_login_required;
    try {
      await axiosInstance.post('https://dryangai.com/api/updateChatbotInfo/', {
        chatbot_id: selectedChatbotID,
        chatbot_login_required: newStatus,
      });
      getChatbotInfo();
    } catch (error) {
      console.error('Error updating chatbot login requirement:', error);
    }
  };

  const ToggleSwitch = ({ isChecked, onChange }) => (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span className={`slider ${isChecked ? 'green' : 'red'}`}></span>
    </label>
  );

  return (
    <div>
      <Helmet>
        <title>Configure my chatbots -- Dr. Yang AI</title>
        <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
        <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
      <h2>{t('chatbotConfiguration')}</h2>
      <Sidebar
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
        />
        <div
            className={`main-content ${
              isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'
            }`}
        ></div>
      <h4>{t('step1')}: {t('chooseChatbot')}</h4>
      {t('chooseAChatbotToConfigure')}:
      <select name="selectedChatbotName" className="form-control" onChange={handleChatbotChange}>
        <option value="">{t('selectAChatbot')}</option>
        {chatbots.map(chatbot => (
          <option key={chatbot.chatbot_id} value={chatbot.chatbot_name}>
            {chatbot.chatbot_name}
          </option>
        ))}
      </select>
      {!selectedChatbotName && <span style={{ color: 'red' }}>{t('pleaseSelectAChatbot')}</span>}
      {selectedChatbotName && <span style={{ color: 'green' }}>{t('selected')} {selectedChatbotName}</span>}
      
      {selectedChatbotName && <div>
      <br />
      <h4>{t('step2')}: {t('configureChatbot')}</h4> 
        <p>
          <img 
            className="profile-image"
            src={`https://dryangai.com/api/media/chatbots/${selectedChatbotName}/Logo.png?t=${imageTimestamp}`} 
            alt="chatbot"
            onClick={handleImageClick}
            onError={(e) => e.target.src = 'https://dryangai.com/api/media/chatbots/defaultProfileImage.png'} 
            style={{ cursor: 'pointer' }} 
          />
          <input
            type="file"
            ref={profileImageInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
        </p>
        {t('Chatbot Alias')}:
        <input type="text" 
          className="form-control" 
          value={chatbotAlias} 
          onChange={(event) => setChatbotAlias(event.target.value)}
        />
      <button onClick={updateChatbotInfo}>{t('save')}</button>
      <br />
      <br />

      {t('allowedDomains')}:
      <br />
      ({t('add * to allow all domains')})
      <br />
      <span style={{ color: 'green' }}>dryangai.com ({t('default')})</span>
      <span style={{ color: 'green' }}>www.dryangai.com ({t('default')})</span>
      {allowedDomains.map(allowedDomain => (
        <div key={allowedDomain.domain_id}>
          <span style={{ color: 'green' }}>{allowedDomain.domain}</span>
          <button onClick={() => deleteDomain(allowedDomain.domain_id)}>{t('delete')}</button>
        </div>
      ))}
      <input
        type="text"
        className="form-control"
        onChange={handleDomainChange}
        value={domain}
      />
      <button onClick={addDomain}>{t('addDomain')}</button>
      <br />
      <br />

      {t('greetingMessage')}:
      <textarea
        className="form-control"
        onChange={(event) => setGreetingMessage(event.target.value)}
        value={greetingMessage}
      />
      <button onClick={updateChatbotInfo}>{t('save')}</button>
      <br />
      <br />

      {t('userInputPlaceHolderText')}:
      <textarea
        className="form-control"
        onChange={(event) => setUserInputPlaceHolder(event.target.value)}
        value={userInputPlaceHolder}
      />
      <button onClick={updateChatbotInfo}>{t('save')}</button>
      
      <br />
      <br />

      {t('exampleQuestions')}:
      <br />
      {exampleQuestions.map(exampleQuestion => (
        <div key={exampleQuestion.question_id}>
          <span style={{ color: 'green' }}>{exampleQuestion.question}</span>
        <Button onClick={() => deleteExampleQuestion(exampleQuestion.question_id)}>{t('delete')}</Button>
        </div>
      ))}
      <input
        type="text"
        className="form-control"
        onChange={handleExampleQuestionChange}
        value={question}
      />
      <button onClick={addExampleQuestion}>{t('addExampleQuestions')}</button>
      <br />
      <br />

      {t('chatbotPersonality')}:
      <br />
      <textarea
        className="form-control"
        onChange={(event) => setChatbotPersonality(event.target.value)}
        value={chatbotPersonality}
      />
      <button onClick={updateChatbotInfo}>{t('save')}</button>

      <br />
      <br />

      {t('customizedOrGeneral')}:
      <br />
      <ToggleSwitch
        isChecked={chatbotRAGStatus}
        onChange={() => handleChatbotRAGStatusToggle(selectedChatbotID, chatbotRAGStatus)}
      />
      <br />
      {chatbotRAGStatus && <span style={{ color: 'green' }}>{t('chatbotOnlyResponsesBasedOnTheCustomizedMemory')}</span>}
      {!chatbotRAGStatus && <span style={{ color: 'red' }}>{t('chatbot answers questions without the customized memory')}</span>}
      
      <br />
      <br />

      {t('Require Login to Chat and Track Chat History')}:
      <br />
      <ToggleSwitch
        isChecked={chatbot_login_required}
        onChange={() => handleChatbotLoginRequiredToggle(selectedChatbotID, chatbot_login_required)}
      />
      <br />
      {chatbot_login_required && <span style={{ color: 'green' }}>{t('Require use login to chat and track chat history')}</span>}
      {!chatbot_login_required && <span style={{ color: 'red' }}>{t('No need to login to chat with chatbot, and chat history is not stored')}</span>}

      <br />
      <br />

      {t('Chatbot Language')}:
      <br />
      {t('Select the main language for the chatbot')}:
      <select name="chatbotLanguage" className="form-control" value={chatbotLanguage} onChange={(event) => setChatbotLanguage(event.target.value)}>
        <option value="">{t('selectLanguage')}</option>
        <option value="English">English</option>
        <option value="Chinese">Chinese</option>
      </select>
      <button onClick={updateChatbotInfo}>{t('save')}</button>
      
      <br />
      <br />

      {t('AI Model Selection')}:
      <br />
      {t('After changing the model, the chatbot must be initialized and recustomized, otherwise will not work.')}:
      <select
        name="chatbotModel"
        className="form-control"
        value={chatbotModel}
        onChange={(event) => setChatbotModel(event.target.value)}
      >
        <option value="">{t('selectModel')}</option>
        {modelOptions.map(model => (
          <option key={model} value={model}>
            {model}
          </option>
        ))}
      </select>
      <button onClick={updateChatbotInfo}>{t('save')}</button>

      </div>
      }

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal> 
    </div>
  );
}

export default ChatbotConfigPage;
