import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Sidebar from './Sidebar';
import './HomePage.css';
import PaymentPage from './PaymentPage';
import ChatbotConfigPage from './ChatbotConfigPage';
import DeployChatbots from './ChatbotDeploymentPage';
import ChatbotsPage from './ChatbotsPage';
import CreateChatbot from './CreateChatbot';
import CustomizeChatbots from './CustomizeChatbots';
import UserProfile from './userProfilePage'; // Import the UserProfile component
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';

function HomePage() {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('profile');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [chatbots, setChatbots] = useState([]);

  const navigate = useNavigate();
  const menuRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const username = user ? user.username : null;
  const user_id = user ? user.user_id : null;

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setSelectedUser(null);
    navigate('/');
  };

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const getUserProfile = async (username) => {
    try {
      const response = await axiosInstance.post(
        'https://dryangai.com/api/getUserProfile/',
        {
          username,
        }
      );
      setSelectedUser(response.data.user);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Error fetching user data');
      setLoading(false);
    }
  };

  const getUserChatbots = async (user_id) => {
    try {
      const response = await axiosInstance.post(
        'https://dryangai.com/api/getUserChatbots/',
        {
          user_id,
        }
      );
      setChatbots(response.data.chatbots);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user chatbots:', error);
      setError('Error fetching user chatbots');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activePage === 'profile' && username) {
      getUserProfile(username);
    }
    if (activePage === 'chatbots' && user_id) {
      getUserChatbots(user_id);
      getUserProfile(username);
    }
    if (activePage === 'customizeChatbots' && user_id) {
      getUserChatbots(user_id);
    }
    if (activePage === 'deployChatbots' && user_id) {
      getUserChatbots(user_id);
    }
    if (activePage === 'configureChatbots' && user_id) {
      getUserChatbots(user_id);
    }
  }, [activePage, username, user_id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleMenuClick = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const addToCreditBalance = () => {
    setShowPaymentModal(true);
  };

  const handlePaymentSuccess = () => {
    setShowPaymentModal(false);
    getUserProfile(username);
  };

  return (
    <div className="container">
      <Helmet>
        <title>Customize your own chatbot -- Dr. Yang AI</title>
        <meta
          name="description"
          content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.."
        />
        <meta
          name="keywords"
          content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge"
        />
      </Helmet>
      {user ? (
        <>
          <div className="welcomeUser">
            <p
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {user && (
                <img
                  className="profile-image-header"
                  src={`https://dryangai.com/api/media/${user.profile_image_url}?t=${Date.now()}`}
                  alt="Profile"
                  onClick={toggleMenu}
                  onError={(e) =>
                    (e.target.src =
                      'https://dryangai.com/api/media/users/defaultProfileImage.png')
                  }
                />
              )}
            </p>
            {menuVisible && (
              <div className="menu" ref={menuRef}>
                <div className="menu-profile">
                  <img
                    className="profile-image-header"
                    src={`https://dryangai.com/api/media/${user.profile_image_url}?t=${Date.now()}`}
                    alt="Profile"
                    onError={(e) =>
                      (e.target.src =
                        'https://dryangai.com/api/media/users/defaultProfileImage.png')
                    }
                  />
                  <div className="menu-username">
                    <span style={{ display: 'block' }}>
                      {user.username}&nbsp;&nbsp;
                    </span>
                    <span style={{ display: 'block' }}>
                      {user.email}&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
                <div className="menu-divider">
                  <a href="#" onClick={() => handleMenuClick('profile')}>
                    &nbsp;&nbsp;{t('Profile')}
                  </a>
                </div>
                <div className="menu-divider">
                  <a href="#" onClick={() => handleMenuClick('createChatbot')}>
                    &nbsp;&nbsp;{t('Create Chatbot')}
                  </a>
                </div>
                <div className="menu-divider">
                  <a href="#" onClick={() => handleMenuClick('chatbots')}>
                    &nbsp;&nbsp;{t('chatbotList')}
                  </a>
                </div>
                <div className="menu-divider">
                  <a href="#" onClick={() => handleMenuClick('customizeChatbots')}>
                    &nbsp;&nbsp;{t('Customize Chatbot')}
                  </a>
                </div>
                <div className="menu-divider">
                  <a
                    href="#"
                    onClick={() => handleMenuClick('configureChatbots')}
                  >
                    &nbsp;&nbsp;{t('Config Chatbot')}
                  </a>
                </div>
                <div className="menu-divider">
                  <a href="#" onClick={() => handleMenuClick('deployChatbots')}>
                    &nbsp;&nbsp;{t('Deploy Chatbot')}
                  </a>
                </div>
                <div className="menu-divider">
                  <a href="#" onClick={handleLogout}>
                    &nbsp;&nbsp;{t('Logout')}
                  </a>
                </div>
              </div>
            )}
          </div>
          <Sidebar
            onMenuClick={handleMenuClick}
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
          />
          <div
            className={`main-content ${
              isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'
            }`}
          >
            {activePage === 'home' && (
              <div className="home-content">
                <h2>Welcome to the Home Page</h2>
                <p>This is the home page content.</p>
              </div>
            )}

            {activePage === 'profile' && selectedUser && (
              <UserProfile
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                user_id={user_id}
                axiosInstance={axiosInstance}
                setModalMessage={setModalMessage}
                setShowModal={setShowModal}
                addToCreditBalance={addToCreditBalance}
              />
            )}

            {activePage === 'profile' && !selectedUser && error && (
              <p>{error}</p>
            )}

            {activePage === 'createChatbot' && (
              <CreateChatbot
                setActivePage={setActivePage}
                user_id={user_id}
                username={username}
                axiosInstance={axiosInstance}
                setModalMessage={setModalMessage}
                setShowModal={setShowModal}
              />
            )}

            {activePage === 'chatbots' && (
              <ChatbotsPage user_id={user_id} />
            )}

            {activePage === 'configureChatbots' && (
              <ChatbotConfigPage chatbots={chatbots} user_id={user_id} />
            )}
            {activePage === 'deployChatbots' && (
              <DeployChatbots chatbots={chatbots} user_id={user_id} />
            )}

            {activePage === 'customizeChatbots' && (
              <CustomizeChatbots chatbots={chatbots} user_id={user_id} />
            )}
          </div>
        </>
      ) : (
        <div className="register">
          <p className="links">
            <a href="register" onClick={() => handleMenuClick('register')}>
              {t('register')}
            </a>
            <a href="login" onClick={() => handleMenuClick('login')}>
              {t('login')}
            </a>
          </p>
        </div>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Add to Credit Balance')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentPage user_id={user_id} onSuccess={handlePaymentSuccess} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPaymentModal(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default HomePage;
