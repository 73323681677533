import './App.css'; // This should be at the top of your App.js file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import React, { useEffect, useState } from 'react';
import DrYangAIlogo from './assets/YLogo-removebg-preview.png';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import getUserCountry from './getUserCountry';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import IndexPage from './pages/IndexPage';
import PaymentPage from './pages/PaymentPage';
import ProfilePage from './pages/userProfilePageNew';
import ContactPage from './pages/ContactPage';
import ChatbotsPage from './pages/ChatbotsPageNew';
import CreateChatbotPage from './pages/CreateChatbotNew';
import CustomizeChatbotPage from './pages/CustomizeChatbotsNew';
import RegistrationPage from './pages/RegistrationPage';
import LoginPage from './pages/LoginPage';
import AdminPage from './adminPages/AdminPage';
import ChatbotConfigPage from './pages/ChatbotConfigPageNew';
import ChatbotDeploymentPage from './pages/ChatbotDeploymentPageNew';
import WeatherPage from './pages/WeatherPage';
import AdminLogin from './adminPages/AdminLogin';

function App() {
  const { t } = useTranslation();
  const [country, setCountry] = useState('');
  const [userIP, setUserIP] = useState('');

  useEffect(() => {
    /**
     * Sets the language based on the user's country.
     * If the user's country is 'CN', the language is set to 'zh'.
     * Otherwise, the language is set to 'en'.
     * @returns {Promise<void>} A promise that resolves once the language is set.
     */
    const setLanguage = async () => {
      const [userCountry, userIP] = await getUserCountry();
      setCountry(userCountry); // Store the country in state
      setUserIP(userIP); // Store the IP in state for display if needed
      if (userCountry === 'CN' || userIP === '152.7.255.204') {
        i18n.changeLanguage('zh');
      } else {
        i18n.changeLanguage('en');
      }
    };
    setLanguage();
  }, []);

  return (
    <div className="App">
      <Helmet>
      <title>{t('title')}</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge, AI girlfriend, AI boyfriend, AI companions, Chatbot with Personality, Custom Knoledge, Chatbot with memory" />
      
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content="Login to customize your own chatbot -- Dr. Yang AI" />
      <meta property="og:description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://dryangai.com/login" />
      <meta property="og:image" content="https://dryangai.com/static/media/YLogo-removebg-preview.60984f24ae3cb01930af.png" />
      
      <meta name="twitter:title" content="Login to customize your own chatbot -- Dr. Yang AI" />
      <meta name="twitter:description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot." />
      <meta name="twitter:image" content="https://dryangai.com/static/media/YLogo-removebg-preview.60984f24ae3cb01930af.png" />
      
      <link rel="canonical" href="https://dryangai.com/login" />
      
      <meta charset="UTF-8" />
      <html lang="en" />
      <meta name="author" content="Dr. Yang AI" />
    </Helmet>

         
      <header className="App-header">
        <h1 className="App-title">{t('title')}✨</h1>
        {/* <h1 className="slogan">{t('slogan')}</h1> */}
        <a href="/">
          <img src={DrYangAIlogo} className="DrYangAIlogo" alt="logo" />
        </a>
      </header>
      
      <Router>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/chatbots" element={<ChatbotsPage />} />
          <Route path="/create" element={<CreateChatbotPage />} />
          <Route path="/customize" element={<CustomizeChatbotPage />} />
          <Route path="/config" element={<ChatbotConfigPage />} />
          <Route path='/deploy' element={<ChatbotDeploymentPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path='/weather' element={<WeatherPage />} />
          <Route path="/admin-login" element={<AdminLogin />} />
        </Routes>
      </Router>
      
      {/* <div className="App-footer">
        © 2024 Dr. Yang AI. All rights reserved
      </div> */}
    </div>
  );
}

export default App;
