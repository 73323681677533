import React, { useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import './PaymentPage.css'; // Assuming you create a CSS file for styles
import { useTranslation } from 'react-i18next';

function PaymentPage({ user_id, onSuccess }) {
  const { t } = useTranslation();
  const [amount, setAmount] = useState('10.00'); // Set default value to 10.00
  const [isProcessing, setIsProcessing] = useState(false);
  const [isValidAmount, setIsValidAmount] = useState(true);

  const handleAmountChange = (event) => {
    const value = event.target.value;
    const regex = /^(?:[5-9](?:\.\d{1,2})?|(?:[1-8]\d|90)(?:\.\d{1,2})?)$/; // Regex to check if value is between 5 and 90 with at most two decimal places
    setAmount(value);
    setIsValidAmount(regex.test(value));
  };

  return (
    <div>
      <label>
      {t('Amount to add')}:
        <div className="input-wrapper">
          <span className="dollar-sign">$</span>
          <input
            type="number"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            className="rounded-input"
            disabled={isProcessing} // Disable input during processing
          />
        </div>
        <p className={!isValidAmount ? 'error-text' : 'normal-text'}>
          {t('Enter an amount between $5 and $90')}
        </p>
      </label>
      <br /><br />
      <PayPalButton
        amount={amount}
        options={{
          clientId: 'ASaJ8PHHDT6B9WceoHuFAlyaiEuQDUblcZYfDxqVI07eRJumAO1l60qaMCSd4wb1rJLhmpXDYIqAbPwS',
        }}
        onClick={() => {
          if (isValidAmount) {
            setIsProcessing(true);
          } else {
            alert('Please enter a valid amount between $5 and $90 with at most two decimal places.');
          }
        }} // Set processing state on click
        onSuccess={(details, data) => {
          alert("Transaction completed by " + details.payer.name.given_name);

          // OPTIONAL: Call your server to save the transaction
          return fetch("https://dryangai.com/api/paypalTransactionCompleted/", {
            method: "post",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: user_id,  // Use the passed user_id prop
              orderId: data.orderID,
              paidAmount: amount,
              payerName: details.payer.name.given_name
            })
          })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            setIsProcessing(false); // Reset processing state
            onSuccess(); // Call the onSuccess callback
          })
          .catch((error) => {
            console.error('Error:', error);
            setIsProcessing(false); // Reset processing state
          });
        }}
        onError={(err) => {
          console.error("PayPal Checkout onError", err);
          setIsProcessing(false); // Reset processing state
        }}
      />
    </div>
  );
}

export default PaymentPage;
