import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

const CreateChatbot = ({ setActivePage, user_id, username, axiosInstance, setModalMessage, setShowModal }) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null); // Initially null
  const [chatbots, setChatbots] = useState([]); // Array to store existing chatbots
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modelOptions, setModelOptions] = useState([]); // State for model options

  const getUserProfile = async (username) => {
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/getUserProfile/', {
        username,
      });
      setSelectedUser(response.data.user);
      console.log('User:', response.data.user);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const getUserChatbots = async (user_id) => {
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/getUserChatbots/', {
        user_id,
      });
      setChatbots(response.data.chatbots);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user chatbots:', error);
      setError('Error fetching user chatbots');
      setLoading(false);
    }
  };

  // Fetch model options from the backend
  const fetchModelOptions = async () => {
    try {
      const response = await axiosInstance.get('https://dryangai.com/api/getOllamaModels');
      setModelOptions(response.data.model_names); // Set the list of models
    } catch (error) {
      console.error('Error fetching model options:', error);
    }
  };

  // Fetch user profile, user chatbots, and model options on component mount
  useEffect(() => {
    getUserProfile(username);
    getUserChatbots(user_id);
    fetchModelOptions();
  }, [username, user_id]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const chatbotName = formData.get('chatbotName');
    const chatbotDescription = formData.get('chatbotDescription');
    const chatbotModel = formData.get('chatbotModel');
    const chatbotAPIKey = formData.get('chatbotAPIKey');
    const chatbotStatus = formData.get('chatbotStatus');

    // Validation for allowed characters in chatbot name
    const validNamePattern = /^[a-zA-Z0-9_-]+$/;
    if (!validNamePattern.test(chatbotName)) {
      setModalMessage(t('Chatbot name can only contain letters, numbers, underscores, and dashes.'));
      setShowModal(true);
      return;
    }

    axiosInstance.post('https://dryangai.com/api/createChatbot/', {
      chatbotName,
      chatbotDescription,
      user_id,
      chatbotModel,
      chatbotAPIKey,
      chatbotStatus,
    })
      .then(response => {
        setActivePage('chatbots');
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        if (error.response && error.response.data && error.response.data.error === 'Chatbot name already exists, please choose another name for your chatbot') {
          setModalMessage('Chatbot name already exists, please choose another name for your chatbot');
          setShowModal(true);
        } else {
          setModalMessage('An error occurred while submitting the form. Please try again.');
          setShowModal(true);
        }
      });
  };

  // Function to check if the user can create a chatbot based on membership and chatbots_created
  const canCreateChatbot = (selectedUser) => {
    if (!selectedUser) return false;

    const { chatbots_created, membership } = selectedUser;

    if (membership === 'free' && chatbots_created < 2 && chatbots.length < 2) return true;
    if (membership === 'Pro' && chatbots_created <= 20) return true;
    if (membership === 'Enterprise' && chatbots_created <= 1000) return true;

    return false;
  };

  return (
    <div className="chatbot-content">
      <Helmet>
      <title>Create a customized chatbot -- Dr. Yang AI</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
      <h2>{t('createChatbot')}</h2>
      {selectedUser ? (<p>Chatbots Created: {Math.max(selectedUser.chatbots_created, chatbots.length)} (including deleted chatbots)</p>) : (<p>{t('loadingUserEmail')}</p>)}      
      <p>Number of active chatbots: {loading ? t('loading') : chatbots.length}</p> {/* Show the number of chatbots */}
      {error && <p>{t(error)}</p>} {/* Show error message if any */}

      {/* Show form based on membership and chatbots_created */}
      {canCreateChatbot(selectedUser) ? (
        <form onSubmit={handleFormSubmit}>
          <p>{t('name')}: <input type="text" name="chatbotName" placeholder={t('enterChatbotName')} className="form-control" /></p>
          <p>{t('description')}: <input type="text" name="chatbotDescription" placeholder={t('describeChatbot')} className="form-control" /></p>
          <p>{t('model')}: 
          <select name="chatbotModel" className="form-control">
            <option value="">{t('selectModel')}</option>
            {modelOptions.map(model => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
          </p>
          <input type="hidden" name="chatbotAPIKey" defaultValue="sk-proj-Dg4KIqseVDykTc1A0wwQT3BlbkFJwOjMxp7YVy6psi22qfBh" />
          <p>{t('status')}: 
          <select name="chatbotStatus" className="form-control">
            <option value="True">{t('active')}</option>
            <option value="False">{t('inactive')}</option>
          </select>
          </p>
          <p><button type="submit" className="btn btn-primary">{t('createChatbot')}</button></p>
        </form>
      ) : (
        <div>
          <p>{t(`You have reached the limit of chatbots you can create based on your ${selectedUser?.membership || 'current'} membership level.`)}</p>
          <p>{t('Please upgrade your membership to create more chatbots.')}</p>
        </div>
      )}
    </div>
  );
};

export default CreateChatbot;
