import React, { useState, useRef } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function UserProfile({
  selectedUser,
  setSelectedUser,
  user_id,
  axiosInstance,
  setModalMessage,
  setShowModal,
  addToCreditBalance,
}) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({
    password: '',
    email: '',
    phone: '',
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false); // For showing upgrade modal
  const [selectedMembership, setSelectedMembership] = useState(''); // For storing selected membership
  const profileImageInputRef = useRef(null);
  const [imageError, setImageError] = useState('');
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const validExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tiff',
      'webp',
      'svg',
    ];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      setImageError('Only image formats (JPG, JPEG, PNG, GIF) are allowed.');
      return;
    }

    const formData = new FormData();
    formData.append('profile_image', file);
    formData.append('user_id', user_id);

    try {
      const response = await axios.post(
        'https://dryangai.com/api/uploadProfileImage/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const newProfileImageUrl = response.data.profile_image_url;

      setSelectedUser((prevUser) => ({
        ...prevUser,
        profile_image_url: newProfileImageUrl,
      }));

      const user = JSON.parse(localStorage.getItem('user'));
      const updatedUserLocal = { ...user, profile_image_url: newProfileImageUrl };
      localStorage.setItem('user', JSON.stringify(updatedUserLocal));

      setImageTimestamp(response.data.unique_id);
      setImageError('');
    } catch (error) {
      console.error('Error uploading profile image:', error);
      setModalMessage('Error uploading profile image');
      setShowModal(true);
    }
  };

  const handleImageClick = () => {
    profileImageInputRef.current.click();
  };

  const handleEditClick = () => {
    setEditMode(true);
    setUpdatedUser({
      password: selectedUser.password || '',
      email: selectedUser.email,
      phone: selectedUser.phone,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handleSaveClick = async () => {
    try {
      const response = await axiosInstance.post(
        'https://dryangai.com/api/updateUserProfile/',
        {
          user_id,
          ...updatedUser,
        }
      );

      if (response.data.success) {
        setSelectedUser({ ...selectedUser, ...updatedUser });
        setEditMode(false);
        setModalMessage('Profile updated successfully');
        setShowModal(true);
      } else {
        throw new Error(response.data.message || 'Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setModalMessage('Error updating profile: ' + error.message);
      setShowModal(true);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to open the upgrade modal
  const handleUpgradeClick = () => {
    setShowUpgradeModal(true);
  };

  // Function to handle membership upgrade submission
  const handleUpgradeSubmit = async () => {
    try {
      const response = await axiosInstance.post(
        'https://dryangai.com/api/upgrade/',
        {
          user_id,
          membership: selectedMembership,
        }
      );

      if (response.data.success) {
        setSelectedUser({ ...selectedUser, membership: selectedMembership });
        setModalMessage('Membership upgraded successfully');
        setShowModal(true);
        setShowUpgradeModal(false);
      } else {
        throw new Error(response.data.message || 'Failed to upgrade membership');
      }
    } catch (error) {
      console.error('Error upgrading membership:', error);
      setModalMessage('Error upgrading membership: ' + error.message);
      setShowModal(true);
      setShowUpgradeModal(false);
    }
  };

  return (
    <div className="profile-details">
      <h2>{t('User Profile')}</h2>
      <p>
        <img
          className="profile-image"
          src={`https://dryangai.com/api/media/${selectedUser.profile_image_url}?t=${imageTimestamp}`}
          alt="Profile"
          onClick={handleImageClick}
          style={{ cursor: 'pointer' }}
          onError={(e) =>
            (e.target.src =
              'https://dryangai.com/api/media/users/defaultProfileImage.png')
          }
        />
      </p>
      <input
        type="file"
        ref={profileImageInputRef}
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />
      {imageError && <p className="error-message">{imageError}</p>}
      <p>
        <strong>ID:</strong> {selectedUser.user_id}
      </p>
      <p>
        <strong>{t('username')}:</strong> {selectedUser.username}
      </p>

      {editMode ? (
        <Form>
          <Form.Group>
            <Form.Label>
              <strong>{t('password')}:</strong>
            </Form.Label>
            <div className="password-container">
              <Form.Control
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                value={updatedUser.password}
                onChange={handleInputChange}
              />
              <FontAwesomeIcon
                icon={passwordVisible ? faEyeSlash : faEye}
                className="password-icon"
                onClick={togglePasswordVisibility}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <strong>{t('email')}:</strong>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={updatedUser.email}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <strong>{t('phone')}:</strong>
            </Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={updatedUser.phone}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Button variant="primary" onClick={handleSaveClick}>
            {t('Save Changes')}
          </Button>
          <Button variant="secondary" onClick={() => setEditMode(false)}>
            {t('Cancel')}
          </Button>
        </Form>
      ) : (
        <>
          <p>
            <strong>{t('password')}:</strong> ********
          </p>
          <p>
            <strong>{t('email')}:</strong> {selectedUser.email}
          </p>
          <p>
            <strong>{t('phone')}:</strong> {selectedUser.phone}
          </p>
          <Button variant="primary" onClick={handleEditClick}>
            {t('Edit Profile')}
          </Button>
        </>
      )}
      <p>
        <strong>{t('membership')}:</strong> {selectedUser.membership}
        <Button variant="link" onClick={handleUpgradeClick}>
          {t('Upgrade')}
        </Button>
      </p>
      <p>
        <strong>{t('chatbots_created')}:</strong> {selectedUser.chatbots_created}
      </p>

      <p>
        <strong>{t('creditBalance')}:</strong> ${selectedUser.balance}{' '}
        <button className="btn btn-primary" onClick={addToCreditBalance}>
          {t('addCredit')}
        </button>
      </p>

      {/* Modal for upgrading membership */}
      <Modal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Upgrade Membership')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Check
              type="radio"
              label="Free"
              name="membership"
              value="free"
              onChange={(e) => setSelectedMembership(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="Pro"
              name="membership"
              value="Pro"
              onChange={(e) => setSelectedMembership(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="Enterprise"
              name="membership"
              value="Enterprise"
              onChange={(e) => setSelectedMembership(e.target.value)}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpgradeModal(false)}>
            {t('Close')}
          </Button>
          <Button variant="primary" onClick={handleUpgradeSubmit}>
            {t('Submit')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserProfile;
